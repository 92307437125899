@font-face {
    src: url(../../../fonts/kenyan_coffee_rg.ttf);
    font-family: "coffee_rg";
    font-weight: 300;
}
  
@font-face {
    src: url(../../../fonts/kenyan_coffee_rg_it.ttf);
    font-family: "coffee_rg_it";
    font-weight: 300;
}
  
@font-face {
    src: url(../../../fonts/kenyan_coffee_bd.ttf);
    font-family: "coffee_bd";
    font-weight: 300;
}
  
.Italic {
    text-align: center;
    font-family: "coffee_rg_it";
    scroll-behavior: smooth;
}
  
.Normal {
    text-align: center;
    font-family: "coffee_rg";
}

#Image:hover {
    transform: scale(1.1);
}

#Cover:hover > #Image {transform: scale(1.1);}