.drawer ::-webkit-scrollbar {
    width: 0rem;
    display: none;
}

::-webkit-scrollbar-track {
    /* background: #201e1f; */
    background: transparent;
}

::-webkit-scrollbar-thumb {
    /* background: gray; */
    background: transparent;
    border-radius: 100vw;
    /* border: 4px solid #201e1f; */
}