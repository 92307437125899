@font-face {
  src: url(../fonts/kenyan_coffee_rg.ttf);
  font-family: "coffee_rg";
  font-weight: 300;
}

@font-face {
  src: url(../fonts/kenyan_coffee_rg_it.ttf);
  font-family: "coffee_rg_it";
  font-weight: 300;
}

@font-face {
  src: url(../fonts//kenyan_coffee_bd.ttf);
  font-family: "coffee_bd";
  font-weight: 300;
}

.Italic {
  text-align: center;
  font-family: "bluehighway";
  scroll-behavior: smooth;
}

.Normal {
  text-align: center;
  font-family: "coffee_rg";
}

html {
  scroll-behavior: smooth;
}

.ctn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  scroll-behavior: smooth;
  margin: 0;
}